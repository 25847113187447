<template>
    <div class='card'>
        <div class='card-header' v-if='!hideHeader'>
            <h5 class="card-title">{{ title }}</h5>
            <div>
                <slot name='actions'></slot>
            </div>
        </div>
        
        <div class='card-body'>
            <b-row v-if='hasFilterActions'>
                <b-col cols=10>
                    <slot name='filter'></slot>
                </b-col>
                <b-col align-self='end' class='pb-3'>
                    <slot name='filter_actions'></slot>
                </b-col>
            </b-row>

            <slot v-else name='filter'></slot>

            <slot name='table'></slot>


            <div class="table-responsive recentOrderTable">
                <b-table hover :items="collection? collection.data : []" :fields="fields ? fields : []"
                    :busy="loading">
                    <template #table-busy>
                        <loader/>
                    </template>
                    <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
                        <slot :name="slotName" v-bind="slotScope"></slot>
                    </template>
                </b-table>
            </div>
            <div class='row justify-content-md-center' v-if='meta.last_page && meta.last_page != 1'>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="meta.total"
                    :per-page="meta.per_page"
                    @input="$emit('pageChanged', currentPage)"
                    ></b-pagination>
            </div>
        </div>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
export default {
    props: ['title', 'collection', 'fields', 'loading', 'hideHeader', "modalObject", "hasFilterActions"],
    data: function() {
        return {
            currentPage: 1,
        };
    },
    computed: {
        meta: function() {
            if (!this.collection) {
                return {};
            }
            var meta = this.collection.meta ? this.collection.meta : {};
            return meta;
        }
    },
    watch:{
        modalObject:{
            deep: true,
            handler() {
                if (this.modalObject) {
                    this.currentModal = this.modalObject.currentModal;
                    this.currentModalData = this.modalObject.currentModalData;
                    this.modalCallback = this.modalObject.modalCallback;
                    this.modalClosed = this.modalObject.modalClosed;
                }
            }
        },
    }
    
}
</script>
